<template>
    <div>
        <QSubmenu title="My Quility" :subment="submenu" :buttons="buttons" />

        <router-view />
    </div>
</template>

<script>
import QTabs from '@/components/navigation/QTabs.vue';
import QTabDropdown from '@/components/navigation/QTabDropdown.vue';
import QPage from '@/CMS/views/index.vue'
import QQuilityRxLeaderboards from '@/components/datatables/QQuilityRxLeaderboards.vue'
import QSubmenu from '@/components/navigation/Submenus/QSubmenu.vue'
import EnrollmentPage from './pages/Enrollment.vue'

export default {
    components: { QTabs, QTabDropdown, QPage, QQuilityRxLeaderboards, QSubmenu, EnrollmentPage },

    computed: {
        submenu() {
            return [{
                label: "My Quility",
                to: "/my-quility"
            }]
        },
        buttons: () => ([
            {
                label: "My Quility",
                to: "/my-quility",
                icon: "fas fa-arrow-alt-circle-right",
            },
            {
                label: "Enrollment",
                to: "/my-quility/enrollment",
                icon: "fas fa-arrow-alt-circle-right",
            },
            {
                label: "Resources",
                icon: "fas fa-arrow-alt-circle-down",
                children: [
                    {
                        label: "My Quility",
                        to: "/my-quility/resources/my-quility",
                    },
                    {
                        label: "Quility RX",
                        to: "/my-quility/resources/quility-rx",
                    }
                ],
            },
            {
                label: "My Points",
                to: "/my-quility/my-points",
                icon: "fas fa-arrow-alt-circle-right",
            },
            {
                label: "Leaderboards",
                icon: "fas fa-arrow-alt-circle-down",
                children: [
                    {
                        label: "My Quility Leaderboards",
                        to: "/my-quility/leaderboards/my-quility",
                    },
                    {
                        label: "Quility RX Leaderboards",
                        to: "/my-quility/leaderboards/quility-rx",
                    }
                ],
            },
        ])
    }
}
</script>