<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-product-library-agent-submenu></q-product-library-agent-submenu>
        <v-col cols="12" style="padding-top:100px;">
            <!-- help and wayfinding -->
            <v-col cols="12" class="text-right">
                <!--
                <v-bottom-sheet v-model="sheet">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="pink" v-on="on">
                            <v-icon>fas fa-question-circle</v-icon>
                        </v-btn>
                    </template>
                    <v-sheet class="text-left px-7" height="auto">
                        <div class="py-3" style="max-width:900px; margin:0 auto;">
                            <p class="q-display-2">Product Concepts</p>
                            <p>Product Conecpts is part of the Symmetry Inranet. This section will be editable and updatable by the Marketing team.</p>
                            <p>It will include:</p>
                            <ul>
                                <li>Training documents (PDF) for each product type</li>
                                <li>Training videos for each product type</li>
                                <li>Carriers for each product</li>
                            </ul>
                            <v-btn class="mt-6" flat color="error" @click="sheet = !sheet">close</v-btn>
                        </div>
                    </v-sheet>
                </v-bottom-sheet>
            -->
            </v-col>
            <!-- /help and wayfinding -->
            <template>
                <QTabs class="q-text-left lighest_grey" background-color="q_symm_blue" dark>
                    <v-tab @click="logTabActivty('Final Expense')">
                        Mortgage Protection
                        <v-icon right>fas fa-arrow-alt-circle-right</v-icon>
                    </v-tab>
                    <v-tab v-if="hasDivision('Field')" @click="logTabActivty('Quility Level Term')">
                        Quility Level Term
                        <v-icon right>fas fa-arrow-alt-circle-right</v-icon>
                    </v-tab>
                    <v-tab @click="logTabActivty('Final Expense')">
                        Final Expense
                        <v-icon right>fas fa-arrow-alt-circle-right</v-icon>
                    </v-tab>
                    <v-tab v-if="hasDivision('Field')" @click="logTabActivty('Critical Illness')">
                        Critical Illness
                        <v-icon right>fas fa-arrow-alt-circle-right</v-icon>
                    </v-tab>
                    <v-tab v-if="hasDivision('Field')" @click="logTabActivty('Disability Income')">
                        Disability Income
                        <v-icon right>fas fa-arrow-alt-circle-right</v-icon>
                    </v-tab>
                    <v-tab @click="logTabActivty('Debt Free Life')">
                        Debt Free Life
                        <v-icon right>fas fa-arrow-alt-circle-right</v-icon>
                    </v-tab>
                    <v-tab v-if="hasDivision('Field')" @click="logTabActivty('Quility Retirement Solutions')">
                        Quility Retirement Solutions
                        <v-icon right>fas fa-arrow-alt-circle-right</v-icon>
                    </v-tab>
                    <v-tab @click="logTabActivty('IUL')">
                        IUL
                        <v-icon right>fas fa-arrow-alt-circle-right</v-icon>
                    </v-tab>
                    <v-tab v-if="hasDivision('Field')" @click="logTabActivty('Group Plans')">
                        Group Plans
                        <v-icon right>fas fa-arrow-alt-circle-right</v-icon>
                    </v-tab>
                    <v-tab v-if="hasDivision('Field')" @click="logTabActivty('Medicare Supplement')">
                        Medicare Supplement
                        <v-icon right>fas fa-arrow-alt-circle-right</v-icon>
                    </v-tab>
                    <v-tab v-if="hasDivision('Field')" @click="logTabActivty('Medicare Supplement')">
                        Long Term Care
                        <v-icon right>fas fa-arrow-alt-circle-right</v-icon>
                    </v-tab>
                    <v-tab v-if="hasDivision('Field')" @click="logTabActivty('Smart Start')">
                        Smart Start
                        <v-icon right>fas fa-arrow-alt-circle-right</v-icon>
                    </v-tab>
                    <v-tab v-if="hasDivision('Corporate')" @click="logTabActivty('PCS')">
                        PCS
                        <v-icon right>fas fa-arrow-alt-circle-right</v-icon>
                    </v-tab>
                    <v-tab v-if="hasDivision('Corporate')" @click="logTabActivty('Emergency Response')">
                        Emergency Response
                        <v-icon right>fas fa-arrow-alt-circle-right</v-icon>
                    </v-tab>
                    <v-tab-item class="lighest_grey">
                        <page v-if="hasDivision('Field')" slug="mortgage-protection"></page>
                        <page v-if="hasDivision('Corporate')" slug="corp-mortgage-protection"></page>
                    </v-tab-item>
                    <v-tab-item v-if="hasDivision('Field')" class="lighest_grey">
                        <page slug="quility-level-term"></page>
                    </v-tab-item>
                    <v-tab-item class="lighest_grey">
                        <page v-if="hasDivision('Field')" slug="final-expense"></page>
                        <page v-if="hasDivision('Corporate')" slug="corp-final-expense"></page>
                    </v-tab-item>
                    <v-tab-item v-if="hasDivision('Field')" class="lighest_grey">
                        <page slug="critical-illness"></page>
                    </v-tab-item>
                    <v-tab-item v-if="hasDivision('Field')" class="lighest_grey">
                        <page slug="disability-income"></page>
                    </v-tab-item>
                    <v-tab-item class="lighest_grey">
                        <page slug="debt-free-life"></page>
                    </v-tab-item>
                    <v-tab-item v-if="hasDivision('Field')" class="lighest_grey">
                        <page slug="annuity"></page>
                    </v-tab-item>
                    <v-tab-item class="lighest_grey">
                        <page v-if="hasDivision('Field')" slug="iul"></page>
                        <page v-if="hasDivision('Corporate')" slug="corp-iul"></page>
                    </v-tab-item>
                    <v-tab-item v-if="hasDivision('Field')" class="lighest_grey">
                        <page slug="group-plans"></page>
                    </v-tab-item>
                    <v-tab-item v-if="hasDivision('Field')" class="lighest_grey">
                        <page slug="medicare-supplement"></page>
                    </v-tab-item>
                    <v-tab-item v-if="hasDivision('Field')" class="lighest_grey">
                        <page slug="long-term-care"></page>
                    </v-tab-item>
                    <v-tab-item v-if="hasDivision('Field')" class="lighest_grey">
                        <page slug="smart-start"></page>
                    </v-tab-item>
                    <v-tab-item v-if="hasDivision('Corporate')" class="lighest_grey">
                        <page slug="corp-qms"></page>
                    </v-tab-item>
                    <v-tab-item v-if="hasDivision('Corporate')" class="lighest_grey">
                        <page slug="corp-ers"></page>
                    </v-tab-item>
                </QTabs>
            </template>
        </v-col>
        <!-- </v-row> -->
    </v-container>
</template>
<script>
import Page from '@/CMS/views/index.vue'
import QProductLibraryAgentSubmenu from '@/components/navigation/Submenus/QProductLibraryAgentSubmenu.vue';
import QTabs from '../../components/navigation/QTabs.vue'

export default {
    data: () => ({
        inset: false,
        items: [
            'default',
            'vertical',
        ],
        variant: 'default',
        sheet: false,
    }),

    data() {
        return {
            tab: null,
            sheet: false,
        }
    },
    components: {
        Page,
        QProductLibraryAgentSubmenu,
        QTabs,
    },
}
</script>